import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Box,
    VStack,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Button,
    Select,
} from '@chakra-ui/react';
import { BACKEND_URL } from "../../../../../config";
import EditScoresModal from "./CriteriaPage/EditScoresModal";

const ScoresPanel = ({ eventId }) => {
    const [levels, setLevels] = useState([]);
    const [selectedLevel, setSelectedLevel] = useState('');
    const [organizedScores, setOrganizedScores] = useState([]);
    const [editedScores, setEditedScores] = useState([]);
    const [currentCriteriaLabel, setCurrentCriteriaLabel] = useState('');


    useEffect(() => {
        // Fetch levels
        const fetchLevels = async () => {
            try {
                const response = await axios.get(`${BACKEND_URL}/distinct-levels/${eventId}`);
                setLevels(response.data);
                if (response.data.length > 0) {
                    setSelectedLevel(response.data[0]);
                }
            } catch (error) {
                console.error('Error fetching levels:', error);
            }
        };

        fetchLevels();
    }, [eventId]);

    const fetchScores = async () => {
        if (!selectedLevel) return;

        try {
            const response = await axios.get(`${BACKEND_URL}/scores/organized/?event_id=${eventId}&level=${selectedLevel}`);
            setOrganizedScores(response.data.categories);
        } catch (error) {
            console.error('Error fetching scores:', error);
        }
    };

    useEffect(() => {
        fetchScores();
    }, [eventId, selectedLevel]); // Re-fetch when eventId or selectedLevel changes


    const handleLevelChange = (event) => {
        setSelectedLevel(event.target.value);
    };

    const renderJudgeScores = (judgeScores) => {
        return judgeScores.map((judgeScore, index) => (
            <Td key={index}>{`${judgeScore.score} (${judgeScore.percentage.toFixed(2)}%)`}</Td>
        ));
    };

    const [isOpenEditModal, setIsOpenEditModal] = useState(false);

    const onOpenEditModal = (contestant, criteriaLabel, judgeScore) => {
        setIsOpenEditModal(true);
        setCurrentCriteriaLabel(criteriaLabel); // Update the criteria label state
        setEditedScores(judgeScore);
    };

    const onCloseEditModal = () => {
        setIsOpenEditModal(false);
        fetchScores(); // Re-fetch scores to update the state with the edited data
    };

    return (
        <VStack spacing={4}>
            <Select placeholder="Select level" onChange={handleLevelChange} value={selectedLevel}>
                {levels.map((level, index) => (
                    <option key={index} value={level}>
                       Set {level}
                    </option>
                ))}
            </Select>

            <Accordion allowMultiple width="100%">
                {organizedScores.map((category, categoryIdx) => (
                    <AccordionItem key={categoryIdx}>
                        <AccordionButton bg='maroon' color="white" _hover={{ bg: "teal.600" }}>
                            <Box flex="1" textAlign="left">
                                {category.category_name}
                            </Box>
                        </AccordionButton>
                        <AccordionPanel pb={4}>
                            <Box overflowX="auto">
                                <Table variant="simple">
                                    <Thead>
                                        <Tr>
                                            <Th>Contestant No.</Th>
                                            <Th>Criteria</Th>
                                            {/* Render judges based on the maximum number found in judge scores */}
                                            {[...Array(Math.max(...category.contestants.map(c => c.judge_scores.length)))].map((_, i) => (
                                                <Th key={i}>Judge {i + 1}</Th>
                                            ))}
                                            <Th>Action</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {category.contestants.map((contestant, contestantIdx) => (
                                            <Tr key={contestantIdx}>
                                                <Td>{contestant.contestant_number}</Td>
                                                <Td>{contestant.criteria_label} ({contestant.criteria_max}%)</Td>
                                                {renderJudgeScores(contestant.judge_scores)}
                                                <Td>
                                                    <Button onClick={() => onOpenEditModal(contestant, contestant.criteria_label, contestant.judge_scores)}>
                                                        Edit
                                                    </Button>
                                                    {isOpenEditModal && (
                                                        <EditScoresModal
                                                            isOpen={isOpenEditModal}
                                                            onClose={onCloseEditModal} // Updated to use the new function
                                                            contestant={contestant}
                                                            criteriaLabel={currentCriteriaLabel}
                                                            judgeScores={editedScores}
                                                            event={eventId}
                                                            level={selectedLevel}
                                                            onSubmit={setEditedScores}
                                                        />
                                                    )}


                                                </Td>
                                            </Tr>
                                        ))}
                                    </Tbody>
                                </Table>
                            </Box>
                        </AccordionPanel>
                    </AccordionItem>
                ))}
            </Accordion>
        </VStack>
    );
};

export default ScoresPanel;