import React, {useState} from 'react';
import { useForm } from 'react-hook-form';
import {
    Text,
    Button,
    FormControl,
    FormLabel,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
    Spacer,
    InputGroup,
    InputLeftElement,
    FormErrorMessage
} from '@chakra-ui/react';
import { FaUser, FaKey } from 'react-icons/fa';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import {BACKEND_URL} from "../../config";


const SignInDialog = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const toast = useToast();

    const handleSignIn = async (data) => {
        setIsLoading(true);
        try {
            const response = await axios.post(`${BACKEND_URL}/token/`, {
                username: data.email, // Replace with your actual field name
                password: data.password,
            });
            const { access_token } = response.data;
            console.log('Token:', access_token);

            localStorage.setItem('token', access_token);

            toast({
                title: "Signed in successfully.",
                description: "You are now logged in.",
                status: "success",
                duration: 3000,
                isClosable: true,
            });

            onClose(); // Close the modal after successful login
        } catch (error) {
            console.error('Error during sign in:', error);
            toast({
                title: "Sign in failed.",
                description: "Invalid username or password.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <Button variant="primary" onClick={onOpen}>
                SIGN IN
            </Button>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader borderBottom="3px solid #800000" color="#800000" fontWeight="bold">
                        SIGN IN
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody as="form" onSubmit={handleSubmit(handleSignIn)}>
                        <FormControl isInvalid={errors.email}>
                            <FormLabel>Username or Email</FormLabel>
                            <InputGroup>
                                <InputLeftElement pointerEvents="none" borderRight="1px solid #800000" px={2} ml={2}>
                                    <FaUser color="gray.300" />
                                </InputLeftElement>
                                <Input
                                    ml={2}
                                    type="text"
                                    placeholder="Username"
                                    {...register("email", { required: "Email is required" })}
                                />
                            </InputGroup>
                            <FormErrorMessage>
                                {errors.email && errors.email.message}
                            </FormErrorMessage>
                        </FormControl>
                        <FormControl mt={4} isInvalid={errors.password}>
                            <FormLabel>Password</FormLabel>
                            <InputGroup>
                                <InputLeftElement pointerEvents="none" borderRight="1px solid #800000" px={2} ml={2}>
                                    <FaKey color="gray.300" />
                                </InputLeftElement>
                                <Input
                                    ml={2}
                                    type="password"
                                    placeholder="Enter your password"
                                    {...register("password", { required: "Password is required" })}
                                />
                            </InputGroup>
                            <FormErrorMessage>
                                {errors.password && errors.password.message}
                            </FormErrorMessage>
                        </FormControl>
                        <ModalFooter display="flex" justifyContent="flex-start">
                            <Text fontSize="sm">
                                or&nbsp;
                                <Link to={"/sign-up"}
                                      onClick={() => {
                                          onClose(); // Close the modal
                                      }}
                                >
                                    Sign-Up here.
                                </Link>
                            </Text>

                            <Spacer />
                            <Button variant="ghost" onClick={onClose}>
                                Cancel
                            </Button>
                            <Button
                                variant="primary"
                                type="submit"
                                isLoading={isLoading}
                                loadingText="Logging In..."
                                spinnerPlacement="end"
                            >
                                Log In
                            </Button>
                        </ModalFooter>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

export default SignInDialog;
