import React from 'react';
import { useForm } from 'react-hook-form';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, FormControl, FormLabel, Input, Button } from '@chakra-ui/react';
import axios from 'axios';

const TeamFormModal = ({ isOpen, onClose, tournamentId, existingTeam, refreshTeams }) => {
    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    React.useEffect(() => {
        if (existingTeam) {
            reset(existingTeam);
        } else {
            reset({ name: '' });
        }
    }, [existingTeam, reset]);

    const onSubmit = async (data) => {
        const url = existingTeam ? `/api/teams/${existingTeam.id}` : '/api/teams/';
        const method = existingTeam ? 'put' : 'post';

        try {
            await axios[method](url, { ...data, tournament_id: tournamentId });
            onClose(); // Close modal on success
            refreshTeams(); // Refresh the list of teams
        } catch (error) {
            console.error('Error submitting form', error);
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{existingTeam ? 'Edit Team' : 'Add Team'}</ModalHeader>
                <ModalCloseButton />
                <form onSubmit={handleSubmit(onSubmit)}>
                    <ModalBody>
                        <FormControl isRequired>
                            <FormLabel>Team Name</FormLabel>
                            <Input type="text" {...register("name", { required: true })} />
                            {errors.name && <span>This field is required</span>}
                        </FormControl>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} type="submit">
                            {existingTeam ? 'Update Team' : 'Create Team'}
                        </Button>
                        <Button variant="ghost" onClick={onClose}>Cancel</Button>
                    </ModalFooter>
                </form>
            </ModalContent>
        </Modal>
    );
};

export default TeamFormModal;
