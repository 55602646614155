import {
    Box,
    Button, Center,
    Checkbox,
    Container,
    Divider,
    FormControl,
    FormLabel,
    Heading,
    HStack,
    Image,
    Input,
    Link,
    Stack,
    Text, useToast,
} from '@chakra-ui/react';
import { useForm, Controller } from "react-hook-form";
import axios from "axios";
import {BACKEND_URL} from "../../config";
import React, {useState} from "react";
import {Navigate, useNavigate} from "react-router-dom";

const SignIn = () => {
    const [isLoading, setIsLoading] = useState(false);
    const toast = useToast();
    const navigate = useNavigate();
    const { control, handleSubmit } = useForm();
    const handleSignIn = async (data) => {
        setIsLoading(true);
        try {
            const response = await axios.post(`${BACKEND_URL}/token/`, {
                username: data.email, // Replace with your actual field name
                password: data.password,
            });
            const { access_token } = response.data;
            console.log('Token:', access_token);

            localStorage.setItem('token', access_token);

            toast({
                title: "Signed in successfully.",
                description: "You are now logged in.",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
            navigate(-1);
        } catch (error) {
            console.error('Error during sign in:', error);
            toast({
                title: "Sign in failed.",
                description: "Invalid username or password.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Container
            maxW="lg"
            py={{ base: '12', md: '22' }}
            px={{ base: '0', sm: '8' }}
        >
            <Stack spacing="8"
            >
                <Stack spacing="6" >
                    <Center>
                        <Image src="logo.png" boxSize="100px" />
                    </Center>
                    <Stack
                        spacing={{ base: '2', md: '3' }}
                        textAlign="center"
                    >
                        <Heading
                            size={{ base: 'xs', md: 'sm' }}
                        >
                            Log in to your account
                        </Heading>
                        <Text color="fg.muted">
                            Don't have an account? <Link href="/sign-up">
                            <Text as="b">
                                Sign up
                            </Text>

                        </Link>
                        </Text>
                    </Stack>
                </Stack>
                <Box
                    py={{ base: '0', sm: '8' }}
                    px={{ base: '4', sm: '10' }}
                    bg={{ base: 'transparent', sm: 'bg.surface' }}
                    boxShadow={{ base: 'none', sm: 'md' }}
                    borderRadius={{ base: 'none', sm: 'xl'}}
                >
                    <form onSubmit={handleSubmit(handleSignIn)}>
                        <Stack spacing="6">
                            <FormControl>
                                <FormLabel htmlFor="email">Username or Email</FormLabel>
                                <Controller
                                    name="email"
                                    control={control}
                                    render={({ field }) => <Input id="email" type="text" {...field} />}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel htmlFor="password">Password</FormLabel>
                                <Controller
                                    name="password"
                                    control={control}
                                    render={({ field }) => <Input id="password" type="password" {...field} />}
                                />
                            </FormControl>
                        </Stack>
                        {/*<HStack justify="space-between">*/}
                        {/*    <Checkbox defaultChecked>Remember me</Checkbox>*/}
                        {/*    <Button variant="link" size="sm"> /!* Changed variant to "link" for a more appropriate style *!/*/}
                        {/*        Forgot password?*/}
                        {/*    </Button>*/}
                        {/*</HStack>*/}
                        <Stack spacing="6" my="3">
                            <Button
                                variant="primary"
                                type="submit"
                                isLoading={isLoading}
                                loadingText="Logging In..."
                                spinnerPlacement="end"
                            >
                                Sign in
                            </Button>

                            <HStack>
                                <Divider />
                            </HStack>
                        </Stack>
                    </form>
                </Box>
            </Stack>
        </Container>
    );
}

export default SignIn;
