import React, { useEffect } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Button,
    Input,
    VStack,
} from '@chakra-ui/react';
import axios from 'axios';
import { useForm, useFieldArray } from 'react-hook-form';
import { BACKEND_URL } from "../../../../../../config";
import { useToast } from '@chakra-ui/react';

const EditScoresModal = ({ isOpen, onClose, contestant, criteriaLabel, judgeScores, event, level, onSubmit }) => {
    // Initialize the form with React Hook Form
    const { register, control, handleSubmit, reset } = useForm({
        defaultValues: { scores: judgeScores }
    });
    const toast = useToast();


    // UseFieldArray for managing dynamic score inputs
    const { fields } = useFieldArray({ control, name: 'scores' });

    // Reset the form whenever judgeScores change
    useEffect(() => {
        reset({ scores: judgeScores });
    }, [judgeScores, reset]);

    // Handler for form submission
    const handleEditScores = handleSubmit(async (formData) => {
        const updatedScores = formData.scores.map((score, index) => ({
            score_id: judgeScores[index].score_id,
            score: parseInt(score.score, 10)
        }));

        try {
            await axios.put(`${BACKEND_URL}/scores/update-bulk/`, { updates: updatedScores });
            onSubmit(updatedScores);
            onClose();
            toast({
                title: "Scores Updated",
                description: "The scores have been successfully updated.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        } catch (error) {
            console.error('Error updating scores:', error);
            toast({
                title: "Error",
                description: "There was an error updating the scores.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    });


    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay bg="rgba(0, 0, 0, 0.4)" />
            <ModalContent>
                <ModalHeader>Edit Scores for {contestant.participant_name} ({criteriaLabel})</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <VStack spacing={4}>
                        {fields.map((field, index) => (
                            <Input
                                key={field.id}  // Use unique id provided by useFieldArray
                                type="number"
                                placeholder="Score"
                                {...register(`scores.${index}.score`)}  // Register the field
                            />
                        ))}
                    </VStack>
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme="blue" onClick={handleEditScores}>
                        Save
                    </Button>
                    <Button onClick={onClose}>Cancel</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default EditScoresModal;
