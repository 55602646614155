import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import axios from 'axios';
import {Heading, Tab, TabList, TabPanel, TabPanels, Tabs} from "@chakra-ui/react";

import JudgesSection from './JudgingEvent/JudgesSection';
import ContestantsSection from './JudgingEvent/ContestantsSection';
import CategoryPage from "./JudgingEvent/CriteriaPage/CategoryPage";
import ResultsPanel from "./JudgingEvent/ResultsPanel";
import ScoresPanel from "./JudgingEvent/ScoresPanel";
import { BACKEND_URL } from "../../../../config";
import TournamentForm from "./ScoreBasedEvent/TournamentForm";
import TeamList from "./ScoreBasedEvent/TeamList";

const EventPage = () => {
    const [event, setEvent] = useState(null);
    const [tabIndex, setTabIndex] = useState(0);
    const { eventName } = useParams();

    useEffect(() => {
        const fetchEvent = async () => {
            const formattedEventName = eventName.replace(/-/g, ' ');

            try {
                const response = await axios.get(`${BACKEND_URL}/events/${formattedEventName}`);
                setEvent(response.data);
            } catch (error) {
                console.error("Error fetching event:", error);
            }
        };

        fetchEvent();
    }, [eventName]);

    const handleTabsChange = (index) => {
        setTabIndex(index);
    };

    if (!event) return <div>Loading...</div>;
    const isScoreBasedEvent = event.event_type === "Score-based Events";

    return (
        <>
        <Heading size="lg">{event.event_name}</Heading>
        <Tabs index={tabIndex} onChange={handleTabsChange}>
            {isScoreBasedEvent ? (
                <>
                    <TabList>
                        <Tab>Tournament</Tab>
                        <Tab>Team</Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            {tabIndex === 0 && <TournamentForm event_id={event.id}/>}
                        </TabPanel>
                        <TabPanel>
                            {tabIndex === 1 && <TeamList />}
                        </TabPanel>
                    </TabPanels>
                </>
            ) : (
                <>
                    {/* Tabs for Judged Events */}
                    <TabList>
                        <Tab>Judges</Tab>
                        <Tab>Contestants</Tab>
                        <Tab>Criteria</Tab>
                        <Tab>Scores</Tab>
                        <Tab>Results</Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            {tabIndex === 0 && <JudgesSection />}
                        </TabPanel>
                        <TabPanel>
                            {tabIndex === 1 && <ContestantsSection />}
                        </TabPanel>
                        <TabPanel>
                            {tabIndex === 2 && <CategoryPage />}
                        </TabPanel>
                        <TabPanel>
                            {tabIndex === 3 && <ScoresPanel eventId={event.id} />}
                        </TabPanel>
                        <TabPanel>
                            {tabIndex === 4 && <ResultsPanel eventId={event.id} />}
                        </TabPanel>
                    </TabPanels>
                </>
            )}
        </Tabs>
        </>
    );
};

export default EventPage;
