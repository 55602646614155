import React from 'react';
import {
    Box,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Text
} from '@chakra-ui/react';

const CategoryResultPrint = React.forwardRef(({ data }, ref) => {
    if (!data) {
        return <div ref={ref}></div>; // Or any placeholder
    }

    // Assuming each contestant score has a 'judgeScores' array
    const judgeHeaders = data.contestant_scores[0].scores.map((_, idx) => (
        <Th key={idx}>Judge {idx + 1}</Th>
    ));

    return (
        <Box ref={ref} p="10mm" fontSize="12pt">
            <Text fontSize="16pt" fontWeight="bold" mb="4">
                {data.category_name}
            </Text>
            <Table variant="simple">
                <Thead>
                    <Tr>
                        <Th>Contestant No.</Th>
                        {judgeHeaders}
                        <Th isNumeric>Total Score</Th>
                        <Th>Rank</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {data.contestant_scores.map((contestant, idx) => (
                        <Tr key={idx}>
                            <Td>{contestant.contestant_number}</Td>
                            {contestant.scores.map((score, scoreIdx) => (
                                <Td key={scoreIdx} isNumeric>{score.score.toFixed(2)}</Td>
                            ))}
                            <Td isNumeric>{contestant.total_percentage.toFixed(2)}</Td>
                            <Td>{contestant.rank}</Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </Box>
    );
});

export default CategoryResultPrint;
