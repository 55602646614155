// src/theme.js
import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
    colors: {
        // Add your custom color scheme here
        customRed: '#900000', // Replace this with your desired red color
        customMaroon: '#780000', // Replace this with your desired maroon color
    },
    components: {
        Button: {
            // Add your custom button styles here, using the custom colors defined above
            baseStyle: {
                fontWeight: 'bold',
            },
            variants: {
                primary: {
                    bg: 'customRed', // Use the customRed color for the primary variant
                    color: 'white', // Text color for the primary variant
                    _hover: {
                        bg: 'customMaroon', // Use the customMaroon color on hover
                    },
                },
            },
        },
        Tab: {
            // Base style for Tab
            baseStyle: {
                color: 'white',
                bg: '#780000',
            },
            // Styles for the different sizes
            sizes: {
                // Your size styles go here
            },
            // Styles for the different variants
            variants: {
                myCustomVariant: { // You can name this variant as you like
                    tab: {
                        _selected: {
                            color: 'white',
                            bg: '#780000',
                            // Add more styles if needed
                        },
                    },
                },
            },
        },
    },
});

export default theme;
