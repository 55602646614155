import React, { useState, useEffect } from 'react';
import { Box, Table, Thead, Tbody, Tr, Th, Td, Button, useDisclosure } from '@chakra-ui/react';
import axios from 'axios';
import TeamFormModal from './TeamFormModal';

const TeamList = ({ tournamentId }) => {
    const [teams, setTeams] = useState([]);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [selectedTeam, setSelectedTeam] = useState(null);

    useEffect(() => {
        fetchTeams();
    }, [tournamentId]);

    const fetchTeams = () => {
        axios.get(`/api/teams/tournament/${tournamentId}`)
            .then(response => {
                setTeams(response.data);
            })
            .catch(error => console.error('Error fetching teams', error));
    };

    const handleEdit = (team) => {
        setSelectedTeam(team);
        onOpen();
    };

    const handleAddTeam = () => {
        setSelectedTeam(null); // Ensure no team is selected for adding a new team
        onOpen();
    };

    return (
        <Box p={5}>
            <Button colorScheme="green" mb={4} onClick={handleAddTeam}>Add Team</Button>
            <Table variant="simple">
                <Thead>
                    <Tr>
                        <Th>Team Name</Th>
                        <Th>Action</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {teams.map(team => (
                        <Tr key={team.id}>
                            <Td>{team.name}</Td>
                            <Td>
                                <Button colorScheme="blue" onClick={() => handleEdit(team)}>Edit</Button>
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
            <TeamFormModal isOpen={isOpen} onClose={onClose} tournamentId={tournamentId} existingTeam={selectedTeam} refreshTeams={fetchTeams} />
        </Box>
    );
};

export default TeamList;
