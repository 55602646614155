import React from 'react';
import {
    Box,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Text
} from '@chakra-ui/react';

const OverallScoresPrintComponent = React.forwardRef(({ overallScores, detailedScores, categoryNames }, ref) => {
    if (!overallScores || !detailedScores || !categoryNames) {
        return <div ref={ref}></div>; // Or any placeholder
    }

    return (
        <Box ref={ref} p="10mm" fontSize="12pt">
            {Object.entries(overallScores).map(([categoryId, scores]) => (
                <Box key={categoryId} mb="20mm">
                    <Text fontSize="16pt" fontWeight="bold" mb="4">
                        {categoryNames[categoryId]}
                    </Text>
                    <Table variant="simple">
                        <Thead>
                            <Tr>
                                <Th>Contestant No.</Th>
                                {/* Dynamically generate category headers */}
                                {scores.length > 0 && Object.values(detailedScores[categoryId][scores[0].contestant_number]).map((detail, index) => (
                                    <Th key={index}>{categoryNames[detail.category_id]}</Th>
                                ))}
                                <Th isNumeric>Overall Score</Th>
                                <Th>Rank</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {scores.map((contestant, idx) => (
                                <Tr key={idx}>
                                    <Td>{contestant.contestant_number}</Td>
                                    {/* Render detailed scores for each contestant */}
                                    {Object.values(detailedScores[categoryId][contestant.contestant_number]).map((detail, index) => (
                                        <Td key={index} isNumeric>
                                            {detail.weighted_score.toFixed(2)}%
                                        </Td>
                                    ))}
                                    <Td isNumeric>{contestant.overall_score.toFixed(2)}%</Td>
                                    <Td>{contestant.rank}</Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </Box>
            ))}
        </Box>
    );
});

export default OverallScoresPrintComponent;
