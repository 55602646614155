import React from 'react';
import { useForm } from 'react-hook-form';
import {
    FormErrorMessage,
    FormLabel,
    FormControl,
    Input,
    Button, Flex, Box, Stack, Center, Image, Heading, Text, Link, Container, Divider
} from '@chakra-ui/react';
import axios from 'axios';
import {BACKEND_URL} from "../../config";
import { useToast } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

const SignUpForm = () => {
    const {
        handleSubmit,
        register,
        getValues,
        formState: { errors, isSubmitting }
    } = useForm();
    const toast = useToast();
    const navigate = useNavigate();
    const onSubmit = async (values) => {
        try {
            const response = await axios.post(`${BACKEND_URL}/register/`, values);
            localStorage.setItem('token', response.data.access_token)

            toast({
                title: "Registration Successful",
                description: "You have been registered successfully.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });

            navigate(-1); // or navigate('/some-route')
        } catch (error) {
            console.error(error);

            toast({
                title: "Registration Failed",
                description: error.response?.data?.detail || "An error occurred during registration.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };


    return (
        <Container
            maxW="lg"
            py={{ base: '12', md: '24' }}
            px={{ base: '0', sm: '8' }}
        >
            <Stack spacing="8"
            >
                <Stack spacing="6" >
                    <Center>
                        <Image src="logo.png" boxSize="100px" />
                    </Center>
                    <Stack
                        spacing={{ base: '2', md: '3' }}
                        textAlign="center"
                    >
                        <Heading
                            size={{ base: 'xs', md: 'sm' }}
                        >
                            Fill the necessary inputs to register.
                        </Heading>
                        <Text color="fg.muted">
                           Already have account?
                            <Link href="/sign-in">
                                <Text as="b">
                                    Sign in
                                </Text>
                            </Link>
                        </Text>
                    </Stack>
                </Stack>
                <Box
                    py={{ base: '0', sm: '8' }}
                    px={{ base: '4', sm: '10' }}
                    bg={{ base: 'transparent', sm: 'bg.surface' }}
                    boxShadow={{ base: 'none', sm: 'md' }}
                    borderRadius={{ base: 'none', sm: 'xl'}}
                >
        <form onSubmit={handleSubmit(onSubmit)}>
            {/* Username */}
            <FormControl isInvalid={errors.username}>
                <FormLabel htmlFor='username'>Username</FormLabel>
                <Input
                    id='username'
                    {...register('username', { required: 'Username is required' })}
                />
                <FormErrorMessage>{errors.username && errors.username.message}</FormErrorMessage>
            </FormControl>

            {/* Email */}
            <FormControl isInvalid={errors.email}>
                <FormLabel htmlFor='email'>Email</FormLabel>
                <Input
                    id='email'
                    type="email"
                    {...register('email', {
                        required: 'Email is required',
                        pattern: {
                            value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                            message: 'Invalid email format'
                        }
                    })}
                />
                <FormErrorMessage>{errors.email && errors.email.message}</FormErrorMessage>
            </FormControl>

            {/* Password */}
            <FormControl isInvalid={errors.password}>
                <FormLabel htmlFor='password'>Password</FormLabel>
                <Input
                    id='password'
                    type='password'
                    {...register('password', { required: 'Password is required' })}
                />
                <FormErrorMessage>{errors.password && errors.password.message}</FormErrorMessage>
            </FormControl>

            {/* Confirm Password */}
            <FormControl isInvalid={errors.confirmPassword}>
                <FormLabel htmlFor='confirmPassword'>Confirm Password</FormLabel>
                <Input
                    id='confirmPassword'
                    type='password'
                    {...register('confirmPassword', {
                        validate: value =>
                            value === getValues('password') || 'Passwords do not match'
                    })}
                />
                <FormErrorMessage>{errors.confirmPassword && errors.confirmPassword.message}</FormErrorMessage>
            </FormControl>

            {/* First Name */}
            <FormControl isInvalid={errors.first_name}>
                <FormLabel htmlFor='first_name'>First Name</FormLabel>
                <Input
                    id="first_name"
                    {...register('first_name', { required: 'First name is required' })}
                />
                <FormErrorMessage>{errors.first_name && errors.first_name.message}</FormErrorMessage>
            </FormControl>

            {/* Last Name */}
            <FormControl isInvalid={errors.last_name}>
                <FormLabel htmlFor='last_name'>Last Name</FormLabel>
                <Input
                    id='last_name'
                    {...register('last_name', { required: 'Last name is required' })}
                />
                <FormErrorMessage>{errors.last_name && errors.last_name.message}</FormErrorMessage>
            </FormControl>
<Divider />
            <Button mt={4} colorScheme='teal' isLoading={isSubmitting} type='submit'>
                Register
            </Button>
        </form>
                </Box>
            </Stack>
        </Container>
    );
};


export default SignUpForm;
