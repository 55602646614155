import {
    Text,
    Box,
    Flex,
    Spacer,
    Image,
    Avatar,
    MenuButton,
    Menu,
    MenuItem,
    MenuList,
    MenuDivider
} from '@chakra-ui/react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import SignInDialog from "../../SignIn/SignInDialog";
import { jwtDecode } from "jwt-decode";
function DashboardNavbar() {
    const hideNavbarPaths = ['/event', '/dashboard'];
    const navigate = useNavigate();
    const location = useLocation();
    const shouldHideNavbar = () => {
        return hideNavbarPaths.some((path) => location.pathname.startsWith(path));
    };
    if (shouldHideNavbar()) {
        return null;
    }

    const token = localStorage.getItem('token');
    let user = null;
    if (token) {
        user = jwtDecode(token);
    }

    const handleSignOut = () => {
        localStorage.removeItem('token');

        navigate('/');
    };


    return (
        <>
        <Box bg="whiteAlpha.100" py={4} px={8}>
            <Flex alignItems="center">
                <Box>
                    <Link to="/">
                        <Image src="/test-logo.png" h={{ base: "7", md: "20" }} />
                    </Link>
                </Box>
                <Spacer />
                <Box as="nav" className="text-lg font-bold">
                    <Link to="/" className="text-red-900 ml-5 mr-3">HOME</Link>
                    <Link to="/announcements" className="text-red-900 mx-3">ANNOUNCEMENTS</Link>
                    <Link to="/tournaments" className="text-red-900 mx-3">EVENTS</Link>
                    <Link to="/about" className="text-red-900 mx-3">ABOUT</Link>

                </Box>
                {user ? (
                    <Flex alignItems="center" ml="4">
                        <Box mr="2">
                            <Text fontWeight="bold" className="text-red-900">{user.first_name}</Text>
                            <Text fontSize="sm">{user.type_name}</Text>
                        </Box>

                        <Menu>
                            <MenuButton as={Avatar}
                                        src={user.profile_image || '/participants/person-placeholder.jpg'}
                                        size={'sm'}
                                        style={{ cursor: 'pointer' }}
                            />
                            <MenuList>
                                <MenuItem as={Link} to="/dashboard">Dashboard</MenuItem>
                                <MenuDivider />
                                <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
                            </MenuList>
                        </Menu>
                    </Flex>
                ) : (
                    <SignInDialog />
                )}
            </Flex>
        </Box>
    <Box bg="maroon" height="3px" />
</>
    );
}

export default DashboardNavbar;