import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Box, FormControl, FormLabel, Input, Button, Select } from '@chakra-ui/react';
import axios from 'axios';

const TournamentForm = ({ eventId }) => {
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();
    const [isExistingTournament, setIsExistingTournament] = useState(false);

    useEffect(() => {
        if (eventId) {
            // Fetch tournament data by event ID
            axios.get(`/api/tournaments/event/${eventId}`)
                .then(response => {
                    const tournamentData = response.data;
                    if (tournamentData) {
                        Object.keys(tournamentData).forEach(key => {
                            setValue(key, tournamentData[key]);
                        });
                        setIsExistingTournament(true);
                    }
                })
                .catch(error => console.error('Error fetching tournament data', error));
        }
    }, [eventId, setValue]);

    const onSubmit = async (data) => {
        const url = isExistingTournament ? `/api/tournaments/${data.id}` : '/api/tournaments/';
        const method = isExistingTournament ? 'put' : 'post';

        try {
            const response = await axios[method](url, { ...data, event_id: eventId });
            console.log('Response:', response.data);
            // Handle success (show message, redirect, etc.)
        } catch (error) {
            console.error('Error submitting form', error);
            // Handle error (show error message, etc.)
        }
    };

    return (
        <Box p={5}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <FormControl isRequired>
                    <FormLabel>Name</FormLabel>
                    <Input type="text" {...register("name", { required: true })} />
                    {errors.name && <span>This field is required</span>}
                </FormControl>

                <FormControl mt={4} isRequired>
                    <FormLabel>Tournament Type</FormLabel>
                    <Select {...register("tournament_type", { required: true })}>
                        <option value="">Select Type</option>
                        <option value="single_elimination">Single Elimination</option>
                        <option value="double_elimination">Double Elimination</option>
                    </Select>
                    {errors.tournament_type && <span>This field is required</span>}
                </FormControl>

                <Button mt={4} colorScheme="blue" type="submit">
                    {isExistingTournament ? 'Update Tournament' : 'Create Tournament'}
                </Button>
            </form>
        </Box>
    );
};

export default TournamentForm;
