// EventUpdateContext.js
import React, { createContext, useState, useContext } from 'react';

const EventUpdateContext = createContext();

export const useEventUpdate = () => useContext(EventUpdateContext);

export const EventUpdateProvider = ({ children }) => {
    const [eventUpdated, setEventUpdated] = useState(false);

    const triggerEventUpdate = () => {
        setEventUpdated(true);
    };

    const resetEventUpdate = () => {
        setEventUpdated(false);
    };

    return (
        <EventUpdateContext.Provider value={{ eventUpdated, triggerEventUpdate, resetEventUpdate }}>
            {children}
        </EventUpdateContext.Provider>
    );
};
