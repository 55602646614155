import {ChakraProvider, CSSReset, Box, useToast} from '@chakra-ui/react';
import {BrowserRouter as Router, Route, Routes, useNavigate} from 'react-router-dom';
import Navbar from './components/MainPage/Navbar/Navbar';
import HomePage from './components/MainPage/HomePage/HomePage';
import AboutPage from './components/MainPage/AboutPage/AboutPage';
import TournamentBracket from './components/MainPage/EventsPage/TournamentBracket';
import AnnouncementsPage from './components/MainPage/AnnouncementsPage/AnnouncementsPage';
import AnnouncementDetails from "./components/Announcements/AnnouncementDetails";
import JudgedEventPage from "./components/JudgedEvent/JudgedEventPage";
import DashboardApp from "./components/DashboardPage/DashboardApp";
import Footer from "./components/MainPage/Footer/Footer";
import theme from './theme';
import EventGateway from "./components/JudgedEvent/EventGateway";
import SignUpForm from "./components/SignUp/SignUpForm";
import {jwtDecode} from "jwt-decode";
import {useEffect, useState} from "react";
import SignIn from "./components/SignIn/SignIn";
import PrivateRoute from "./components/ProtectRoutes/PrivateRoute";
import PublicRoute from "./components/ProtectRoutes/PublicRoute";

function addCSPMetaTag() {
    const meta = document.createElement('meta');
    meta.httpEquiv = "Content-Security-Policy";
    meta.content = "upgrade-insecure-requests";
    document.head.appendChild(meta);
}

// Check environment and conditionally add the tag
if (process.env.NODE_ENV === 'production') {
    addCSPMetaTag();
}

function App() {
    // const [user, setUser] = useState(null);
    // const toast = useToast();
    // const navigate = useNavigate();
    // useEffect(() => {
    //     const token = localStorage.getItem('token');
    //     if (token) {
    //         try {
    //             const decodedUser = jwtDecode(token);
    //             const isExpired = decodedUser.exp * 1000 < Date.now();
    //
    //             if (isExpired) {
    //                 // Handle expired token
    //                 localStorage.removeItem('token');
    //
    //                 // Show a Chakra toast notification
    //                 toast({
    //                     title: 'Session Expired',
    //                     description: 'Your session has expired. Please log in again.',
    //                     status: 'error',
    //                     duration: 3000,
    //                     isClosable: true,
    //                     position: 'top-right',
    //                 });
    //
    //                 navigate('/sign-in');
    //             } else {
    //                 setUser(decodedUser);
    //             }
    //         } catch (error) {
    //             // Handle invalid token
    //             localStorage.removeItem('token');
    //             // Show a Chakra toast notification
    //             toast({
    //                 title: 'Session Invalid',
    //                 description: 'Something went wrong. Please log in again.',
    //                 status: 'error',
    //                 duration: 3000,
    //                 isClosable: true,
    //                 position: 'top-right',
    //             });
    //             navigate('/sign-in');
    //         }
    //     }
    // }, []);

    return (
        <ChakraProvider theme={theme}>
            <CSSReset />
            <Router>
                <Navbar />
                <Box minHeight="80vh">
                    <Routes>
                        <Route exact path="/" element={<HomePage/>} />
                        <Route path="/about" element={<AboutPage/>} />
                        <Route path="/tournaments" element={<TournamentBracket/>} />
                        <Route path="/announcements/*" element={<AnnouncementsPage/>} />
                        <Route path="/announcements/:id" element={<AnnouncementDetails/>} />
                        <Route path="/event/" element={<EventGateway/>} />
                        <Route path="/event/:eventName/:judgeId" element={<JudgedEventPage />} />
                        <Route path="/dashboard/*" element={<PrivateRoute><DashboardApp /></PrivateRoute>} />
                        <Route path="/sign-up/" element={<PublicRoute><SignUpForm /></PublicRoute>} />
                        <Route path="/sign-in/" element={<PublicRoute><SignIn /></PublicRoute>} />
                    </Routes>
                </Box>
                <Footer />
            </Router>
        </ChakraProvider>

    );
}

export default App;
