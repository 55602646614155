import { Navigate } from 'react-router-dom';

const PublicRoute = ({ children }) => {
    const token = localStorage.getItem('token');
    const isAuthenticated = !!token; // Update this based on your authentication logic

    return isAuthenticated ? <Navigate to="/" /> : children;
};
export default PublicRoute;

