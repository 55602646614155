import React from 'react';
import {
    Flex,
    IconButton,
    Text,
    HStack,
    Avatar,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList, useColorModeValue, VStack, Box, Image,
} from '@chakra-ui/react';
import { FiMenu, FiBell, FiChevronDown } from 'react-icons/fi';
import {Link, useNavigate} from "react-router-dom";
import Notification from '../Notification/Notification';
import {jwtDecode} from "jwt-decode";
const MobileNav = ({ onOpen, ...rest }) => {
    const token = localStorage.getItem('token');
    let user = null;
    const navigate = useNavigate();
    if (token) {
        user = jwtDecode(token);
    }
    const handleSignOut = () => {
        localStorage.removeItem('token');

        navigate('/');
    };
    return (
        <Flex
            ml={{ base: 0, md: 60 }}
            px={{ base: 4, md: 4 }}
            height="20"
            alignItems="center"
            bg={useColorModeValue('white', 'gray.900')}
            borderBottomWidth="1px"
            borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
            justifyContent={{ base: 'space-between', md: 'flex-end' }}
            {...rest}
        >
            <IconButton
                display={{ base: 'flex', md: 'none' }}
                onClick={onOpen}
                variant="outline"
                aria-label="open menu"
                icon={<FiMenu />}
            />

            <Link to="/">
                <Image
                    display={{ base: 'flex', md: 'none' }}
                    src="/logo.png"
                    h={{ base: "7", md: "25" }} />
            </Link>

            <HStack spacing={{ base: '0', md: '6' }}>
                <Flex alignItems={'center'}>
                    <Menu>
                        <MenuButton py={2} transition="all 0.3s" _focus={{ boxShadow: 'none' }}>
                            <HStack>
                                <Avatar
                                    size={'sm'}
                                    src={user.profile_image || '/participants/person-placeholder.jpg'}
                                />
                                <VStack
                                    display={{ base: 'none', md: 'flex' }}
                                    alignItems="flex-start"
                                    spacing="1px"
                                    ml="2"
                                >
                                    <Text fontSize="sm">{user.first_name} {user.last_name}</Text>
                                    <Text fontSize="xs" color="gray.600">
                                        {user.type_name}
                                    </Text>
                                </VStack>
                                <Box display={{ base: 'none', md: 'flex' }}>
                                    <FiChevronDown />
                                </Box>
                            </HStack>
                        </MenuButton>
                        <MenuList
                            bg={useColorModeValue('white', 'gray.900')}
                            borderColor={useColorModeValue('gray.200', 'gray.700')}
                        >
                            <MenuDivider />
                            <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
                        </MenuList>
                    </Menu>
                </Flex>
            </HStack>
        </Flex>
    );
};

export default MobileNav;
