import React from 'react';
import {
    Box,
    Text,
    Stack,
    Divider, Avatar,
} from '@chakra-ui/react';
import Calendar from '../Calendar/Calendar';
import {jwtDecode} from "jwt-decode";
import {useNavigate} from "react-router-dom";

const DashboardPage = () => {
    const token = localStorage.getItem('token');
    const navigate = useNavigate();
    let user = null;
    if (token) {
        user = jwtDecode(token);
    }
    return (
        <Box p="4" >
            <Stack direction="row" spacing="4" align="center" mt="4">
                <Avatar
                    size={'2xl'}
                    src={user.profile_image || '/participants/person-placeholder.jpg'}
                />
                <Box>
                    <Text fontSize="xl">{user.first_name}</Text>
                    <Text fontSize="sm">{user.type_name}</Text>
                </Box>
            </Stack>
            <Divider my="4" />
            <Stack spacing="4">
                <Calendar />
            </Stack>
        </Box>
    );
}

export default DashboardPage;
